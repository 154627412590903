<template>
  <div>
    <template v-if="showOnboarding()">
        <div class="alert alert-success">
          👋 Welcome to eCourtDate! Review the new agency <a href="/checklist" class="alert-link">Onboarding Checklist</a> here.
        </div>
      </template>
  </div>
</template>
<script>
export default {

    data() {
        return {
        }
    },

    methods: {

      showOnboarding() {

        if(!this.$root.current_user || !this.$root.current_user.current_agency) {
          return false;
        }

        var created = this.$moment(this.$root.current_user.current_agency.created_at).tz('UTC');

        var tz = this.$moment.tz.guess() || localStorage.getItem('timezone');

        return this.$moment(Date.now()).tz(tz).diff(created, 'hours') <= 24;
      }
    
    }

}
</script>
